export function headerFunc() {
  // ハンバーガーメニュー実装
  $('.navToggle').on('click', function() {
    $('.navToggle').toggleClass('closeNav');
    $('.headerNav').toggleClass('openNav');
  });
  
  $('#jsLang').on('click', function() {
    $(this).toggleClass('open').find('a').slideToggle();
  });
}
