import Swiper from 'swiper/bundle';
export function idxFunc() {
  
  //メインビジュアル
  var topSwiper, topOptions;
  topSwiper = undefined;
  topOptions = {
    loop: true,
    speed: 500,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    mousewheel: {
      forceToAxis: true,
      invert: false
    },
  };
  //スワイプ実行処理
  function initTopSwiper() {
    topSwiper = new Swiper('#mvJs', topOptions);
  }
  initTopSwiper();
  
  
  const swiperSlides = document.getElementsByClassName('swiper-slide');
  const breakPoint = 768;
  let mySwiper;
  let mySwiperBool;
  let responsibleCheck;

  window.addEventListener('resize', () => {
    if (breakPoint < window.innerWidth && mySwiperBool) {
      mySwiper.destroy(false, true);
      mySwiperBool = false;
    } else if (breakPoint >= window.innerWidth && !(mySwiperBool)) {
      createSwiper();
      mySwiperBool = true;
    }
  }, false);
  
  const createSwiper = () => {
    mySwiper = new Swiper('.swiper-container', {
      speed: 500,
      slidesPerView: 1,
      loop: true,
      navigation: {
        nextEl: '.sapNext',
        prevEl: '.sapPrev',
      },
    });
  }

  responsibleCheck = $('#responsibleCheck').width();
  //初回読み込み時
  if (responsibleCheck == 1) {
    mySwiperBool = false;
  } else {
    createSwiper();
    mySwiperBool = true;
  }

}