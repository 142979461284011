// jQueryプラグイン
import 'jquery.easing';
export function commonFunc() {
  $("input").on("blur", function () {
    let error;
    let value = $(this).val();
    if (value == "" || !value.match(/[^\s\t]/)) {
      error = true;
    }
    if (!error) {
      $(this).next('span.wpcf7-not-valid-tip').text("");
    }
  });

  var urlHash = location.hash;
  if (urlHash) {
    $('body,html').stop().scrollTop(0);
    setTimeout(function () {
      var target = $(urlHash);
      var headerHeight = $('#header').outerHeight(true);
      var position = target.offset().top - headerHeight;
      $('body,html').animate({
        scrollTop: position
      }, 800, 'easeInOutQuint');
    }, 100);
  }
  
  let jsYt =$('#jsYt');
  jsYt.on('click', function (e) {
    e.preventDefault(); //リンクの停止
    $(this).html('<iframe width="560" height="315" src="https://www.youtube.com/embed/XE045I3pBDc?autoplay=1&mute=1&rel=0&playsinline=1&loop=1&playlist=XE045I3pBDc" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
  });
}