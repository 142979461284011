// jQueryプラグイン
import 'jquery.easing';
export function buttonActionFunc() {
  $('input').focus(function () {
    $(this).parents().next('.form-element-bar').addClass('add');
    $(this).parents().prev('label').addClass('add');
  });
  $('input').blur(function() {
    if($(this).val() == ''){
      $(this).parents().prev('label').removeClass('add');
      $(this).parents().next('.form-element-bar').removeClass('add');
    }
  });
  var href, headerHeight, target, position;
  var resizecheck = $('#responsibleCheck').width();
  $('a[href^="#"]').on('click', function () {
    href = $(this).attr("href");
    headerHeight = $('#header').outerHeight(true);
    target = $(href == "#" || href == "" ? 'html' : href);
    position = target.offset().top - headerHeight;
    $('body,html').animate({
      scrollTop: position
    }, 800, 'easeInOutQuint');
    if (resizecheck == "2") {
      $('.navToggle').removeClass('closeNav');
      $('.headerNav').removeClass('openNav');
    }
    return false;
  });
}